const valoresPermitidos = [
  "8720181435256", "8720181435331", "8720181435270", "8720181435355", "8720181435324",
  "8720181435317", "8720181435348", "8720181435263", "8710522632666", "8710522632659",
  "8710847974410", "8710847974434", "8710522632680", "8710522632673", "8720181435201",
  "8720181435249", "8720181435218", "8710447224380", "8710447224397", "8720181447068",
  "8710522323137", "8720181449369", "8710522323151"
];

// Custom validations for bootstrap validator
function customValidations(){
    var validatorOptions = {
        settings: {  // <-- not a valid option
            ignore: ":hidden:not('.chosen-select')"  // <- not considered at all
        },
        delay: 100,
        custom: {
            valchosen: function($el){
              if ($el.val() == ''){
                return false;
              }
              else{
                 return true; 
              }
            },
            matchcustom: function($el){
              var _match = $($el).attr('data-matchcustom');
              var _val = $(_match).val();
              return ($el.val().toLowerCase() === _val.toLowerCase());   
            },
            cp: function ($el) {
                return validarCP($el.val());
            },
            telefono: function ($el) {
                return ($el.val()=='') || validarMovil($el.val()) || validarFijo($el.val());
            },
            movil: function ($el) {
                return ($el.val()=='') || validarMovil($el.val());
            },
            dni: function ($el) {
                return (validarDNI($el.val().toUpperCase()) || validarCIF($el.val().toUpperCase())) ;
            },
            barras: function ($el){
                return  valoresPermitidos.includes($el.val());
            },
            mayoredad: function ($el) {
                _fecha = $el.val().split('/');
                
                if (esFechaValida(_fecha[0]+'-'+_fecha[1]+'-'+_fecha[2])){
                  if (validarMayorEdad(_fecha[0], _fecha[1], _fecha[2])){
                      return true;
                  }
                  else{
                      $el.closest('.form-group').attr('data-mensaje-error', 'Lo sentimos, debe de ser mayor de edad para participar.');
                      return false;
                  }
                }
                else{
                  $el.closest('.form-group').attr('data-mensaje-error', 'Por favor, introduzca un formato de fecha correcto (DD/MM/YYYY).');
                  return false;
                }
            },
            fecha: function ($el) {
                _fecha = $el.val().split('/');
                return esFechaValida(_fecha[0]+'-'+_fecha[1]+'-'+_fecha[2]); //dd/mm/aaaa
            },
            hora: function ($el) {
                //_fecha = $el.val().split('/');
                return esHora($el.val()); //hh/mm
            },
            mayorhoy: function($el){
                _fecha = $el.val().split('/');
                return validarMayorHoy(_fecha[0], _fecha[1], _fecha[2]);
            },
            fechalimite: function($el){
                _fecha = $el.val().split('/');
                return validarFechaLimite(_fecha[0], _fecha[1], _fecha[2]);
            },
            fechalimite2: function($el){
              _fecha = $el.val().split('/');
                
                if (esFechaValida(_fecha[0]+'-'+_fecha[1]+'-'+_fecha[2])){
                  if (validarFechaLimite2(_fecha[0], _fecha[1], _fecha[2])){
                      return true;
                  }
                  else{
                      $el.closest('.form-group').attr('data-mensaje-error', 'Lo sentimos, el producto tiene que ser adquirido dentro del período promocional.');
                      return false;
                  }
                }
                else{
                  $el.closest('.form-group').attr('data-mensaje-error', 'Por favor, introduzca un formato de fecha correcto (DD/MM/YYYY).');
                  return false;
                }
            },
            patron: function($el){
                if ($el.val() == '7702018493074'){
                  return true;
                }
                else{
                  return false;
                }
            },
            password: function($el){
                return validarPassword($el);
            },
            pack: function($el){
              
              if ($el.closest('.pack').hasClass('js-visible')){ //comprobar si está visible
                _numpacks = $el.attr('id').split('_');
                _numpack = _numpacks[1];
                return validarPack(_numpack);
              }else{
                return true;
              }  
            },
            packcantidad: function($el){
              
              if ($el.closest('.pack').hasClass('js-visible')){ //comprobar si está visible
                _numpacks = $el.attr('id').split('_');
                _numpack = _numpacks[1];
                return validarPackCantidad(_numpack);
              }else{
                return true;
              }  
            },
            iban: function($el){
              return validarIBAN($el.val());
            },
            importemax: function($el){
              var _importe = parseFloat($el.val().replace(/,/g, '.'));
              //console.log($el.val()+' iomporte:: '+parseFloat($el.val()));
              if (_importe < 6.50){
                return true;
              }else{
                return false;
              }
            },
            file: function ($el) {
                if ($el.closest('.form-group').attr('data-tipo-error') == 0){//todo ok
                    $($el).parents('.form-group').removeClass('has-error');
                    $($el).parents('.form-group').addClass('has-sucess');
                    span_file = $($el).attr('data-span_file');
                    $('#'+span_file).addClass('ticket-ok');
                    // $('#nombreImagen').trigger('change');
                    return true;
                }  
                else{
                    if ($el.closest('.form-group').attr('data-tipo-error') == 1){//no relleno
                          $($el).parents('.form-group').addClass('has-error');
                          $($el).parents('.form-group').removeClass('has-sucess');
                          span_file = $($el).attr('data-span_file');
                          $('#'+span_file).removeClass('ticket-ok');
                         // $('#nombreImagen').trigger('change');
                          $el.closest('.form-group').attr('data-mensaje-error', 'Por favor, adjunta un archivo válido. Recuerda que el archivo debe tener formato JPEG, PNG o PDF y no pesar más de 10MB.');
                          return false;
                    }
                    else{
                        if ($el.closest('.form-group').attr('data-tipo-error') == 2){//error tamaño
                            $($el).parents('.form-group').addClass('has-error');
                            $($el).parents('.form-group').removeClass('has-sucess');
                            span_file = $($el).attr('data-span_file');
                            $('#'+span_file).removeClass('ticket-ok');
                            $el.closest('.form-group').attr('data-mensaje-error', 'Por favor, adjunta un ticket de compra que no sobrepase los 10MB.');
                            return false;
                        }
                        else{ //error extensión
                            $($el).parents('.form-group').addClass('has-error');
                            $($el).parents('.form-group').removeClass('has-sucess');
                            span_file = $($el).attr('data-span_file');
                            $('#'+span_file).removeClass('ticket-ok');
                            $el.closest('.form-group').attr('data-mensaje-error', 'Por favor, adjunta un ticket en formato JPEG, PNG o PDF.');
                            return false;
                        }  

                    }  
                }


               
            }
        },
        errors: {
            cp: "Revisa el código postal",
            telefono: "Revisa el teléfono",
            dni: "Revisa el DNI",
            mayoredad: "Para participar en la promoción tienes que ser mayor de edad",
            fecha: "Introduce una fecha válida",
            hora: "Introduce una hora válida en el formato indicado",
            file: "Selecciona un archivo",
            mayorhoy: "Selecciona una fecha posterior al día de hoy",
            fechalimite: "Selecciona una fecha anterior al 07/04/2018",
            fechalimite2: "Selecciona una fecha comprendida entre el 01/06/2018 y el 30/11/2018",
            valchosen : "Selecciona una opción",
            patron: "Debes introducir un código EAN válido de 13 dígitos.",
            password: "Debes introducir una contraseña válida, mínimo 8 caracteres.",
            movil: "Revisa el teléfono",
            pack: "Selecciona un pack válido",
            packcantidad: "Selecciona un pack válido",
            matchcustom: ".",
            barras: "Introduce un código de barras válido",
            iban: "Introduce un IBAN válido", 
            importemax: "Introduce un importe correcto"
        },
        
    }

    return validatorOptions;

}

function validarFicheroSize(_fichero){
 /* var fileSize = _fichero.files[0].size;
  if (fileSize > 10000000){
    return false;
  }
  return true;*/
}

function validarFicheroExt(_fichero){
 /* var fileName = _fichero.files[0].name;
  _ext = _nom.slice((_nom.lastIndexOf(".") - 1 >>> 0) + 2);
  var validos = ['png', 'jpg', 'pdf', 'jpeg', 'PNG', 'JPG', 'JPEG', 'PDF'];
  if ($.inArray(_ext, validos) >= 0){
    return true;
  }
  return false;*/
}

function validarPassword($el){
  if ($el.val().length >= 8){
    return true;
  }
  else{
    return false;
  }
}

function esHora(_hora){// formato hh:mm

  hora = _hora.split(':');
  
  if (parseInt(hora[0]) < 0 || parseInt(hora[0])>24){
    return false;
  }
  if (parseInt(hora[1]) < 0 || parseInt(hora[1])>59){
    return false;
  }
  if (hora.length > 1){
    if (hora[1].length < 2){ //para no permitir algo del tipo 12:1
      return false;
    }
  }
  else {
      return false;
  }
  return true;

}

function validarMayorHoy_OLD(_dia, _mes, _anno){

    day = _dia;
    month = _mes;
    year = _anno;
    //console.log(day+'-'+month+'-'+year);
    var mydate = new Date(year, month, day);
    var hoy = new Date();
//console.log(mydate.getTime()+'::'+hoy.getTime());
   
    if (hoy.getTime() < mydate.getTime()) {
        return true;
    }
    return false;
}
function validarMayorHoy(_dia, _mes, _anno){
        day = _dia;
        month = _mes;
        year = _anno;
        //console.log(day+'-'+month+'-'+year);
        var date2 = new Date(year, month-1, day);
        //date2.setFullYear(year, month - 1, day);
        
        var hoy = new Date();
        dia = hoy.getDate();
        mes = hoy.getMonth();
        anio= hoy.getFullYear();
        
        var today = new Date(anio, mes, dia);

        //var today = new Date();
        
        
        if (date2<today)
        {   
            return false;
        }
          else
          {
              return true;
          }   
}
function validarMayorEdad(_dia, _mes, _anno){

    day = _dia;
    month = _mes;
    year = _anno;
    //console.log(day+'-'+month+'-'+year);
    var mydate = new Date();
    mydate.setFullYear(year, month - 1, day);

    var maxDate = new Date();
    maxDate.setYear(maxDate.getFullYear() - 18);
    //console.log(maxDate);
    //console.log(mydate);
    if (maxDate < mydate) {
        return false;
    }
    return true;
}
function validarFechaLimite(_dia, _mes, _anno){
    day = _dia;
    month = _mes;
    year = _anno;
        

    var date2 = new Date(year, month-1, day);
        //date2.setFullYear(year, month - 1, day);
    var flimite = new Date('2018', '3', '7');
        //console.log(date2);
        
    if (date2>flimite)
      {   
        return false;
      }
    else
     {
        return true;
      }   
}

function validarFechaLimite2(_dia, _mes, _anno){
    day = _dia;
    month = _mes;
    year = _anno;
        

    var date2 = new Date(year, month-1, day);
        //date2.setFullYear(year, month - 1, day);
    var flimite1 = new Date('2018', '5', '1'); //01/06/2018
    var flimite2 = new Date('2018', '10', '30'); //30/11/2018
        //console.log(date2);
        
    if (date2>=flimite1 && date2<=flimite2)
      {   
        return true;
      }
    else
     {
        return false;
      }   
}

function validarEmail(mail) {
  var exr = /^[0-9a-z_\-\.]+@[0-9a-z\-\.]+\.[a-z]{2,4}$/i;
  return exr.test(mail);
}
function validarMovil(tlf) {
  var exr = /[6|7]\d{8}/;
  return exr.test(tlf);
}
function validarFijo(tlf) {
  var exr = /9\d{8}/;
  return exr.test(tlf);
}
function validarDNI2(_dni){
  _dni = _dni.toUpperCase();
  var exr =  "(([X-Z]{1})([-]?)(\d{7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]{1}))";
  return exr.test(_dni);
}

function isDigit (c){
     return ((c >= "0") && (c <= "9"))
}

function letra_nif(dni){
    var numero = dni % 23 + 1;
    letra='TRWAGMYFPDXBNJZSQVHLCKET';
    letra=letra.substring(numero-1,numero);
    return letra;
}    
    
    
function validar_nie(valor,numero_digitos){
     var i;
     var dni=valor;
     primera_letra = dni.charAt(0);
     if(primera_letra.toUpperCase()!="X" && primera_letra.toUpperCase()!="T" && primera_letra.toUpperCase()!="Y")
          return false;
         
     for (i = 1; i < numero_digitos; i++)
    {  
        var c = dni.charAt(i);
        if (!isDigit(c))
        {
            return false;
        }
    }
    var letra_introducido = letra_nif(dni.substring(1,numero_digitos+1));
    var letra_real = dni.charAt(numero_digitos+1);
    if (letra_introducido == letra_real.toUpperCase())
    {
        return true;
    }
    else
    {
        return false;
    }        
}

function validar_nif(valor){
     var i;
     var dni=valor;
    
     if(dni.length<4){
          return false;    
     }
    
     for (i = 0; i < dni.length-1; i++)
    {  
        var c = dni.charAt(i);
        if (!isDigit(c))
        {
            return false;
        }
    }
    var letra_introducido = letra_nif(dni.substring(0,dni.length-1));
    var letra_real = dni.charAt(dni.length-1);
    if (letra_introducido == letra_real.toUpperCase())
    {
        return true;
    }
    else
    {
        return false;
    }
}    
    
function onlyLettersAndNumbers(str){
  return /^[A-Za-z0-9]*$/.test(str);
}

function validarDNI(valor) {

    if(valor.length<9) {
      return false;
    }

    if (!onlyLettersAndNumbers(valor)){
      return false;
    }
   
    dni = valor.toUpperCase();

    letra = valor.substring(valor.length - 1);
    numero = valor.substring(0, 8);

    if (numero.indexOf("X") >= 0){
      numero = numero.replace("X", 0);
    }
    if (numero.indexOf("Y") >= 0){
      numero = numero.replace("Y", 1);
    }
    if (numero.indexOf("Z") >= 0){
      numero = numero.replace("Z", 2);
    }
   
    // Si es un NIE hay que cambiar la primera letra por 0, 1 ó 2 dependiendo de si es X, Y o Z.
   
    modulo = numero % 23;
    letras_validas = "TRWAGMYFPDXBNJZSQVHLCKE";
    letra_correcta = letras_validas.substring(modulo, modulo+1);   //substr($letras_validas, $modulo, 1);
    //console.log('modulo = '+modulo);
    //console.log('letra = '+letra+', letra valida = '+letra_correcta+', numero = '+numero);
    if(letra_correcta!=letra) {
      return false;
    }else {
      return true;
    }


  //var exr = /6\d{8}/;
  //return exr.test(dni);
  //return ( validar_nif(valor) || validar_nie(valor,7) || validar_nie(valor,8) );
}


function validarCIF(cif) {
  if (!cif || cif.length !== 9) {
    return false;
  }

  var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
  var digits = cif.substr(1, cif.length - 2);
  var letter = cif.substr(0, 1);
  var control = cif.substr(cif.length - 1);
  var sum = 0;
  var i;
  var digit;

  if (!letter.match(/[A-Z]/)) {
    return false;
  }

  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i]);

    if (isNaN(digit)) {
      return false;
    }

    if (i % 2 === 0) {
      digit *= 2;
      if (digit > 9) {
        digit = parseInt(digit / 10) + (digit % 10);
      }

      sum += digit;
    } else {
      sum += digit;
    }
  }

  sum %= 10;
  if (sum !== 0) {
    digit = 10 - sum;
  } else {
    digit = sum;
  }

  if (letter.match(/[ABEH]/)) {
    return String(digit) === control;
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control;
  }

  return String(digit) === control || letters[digit] === control;
}

function checkCif() {
  var cifs = document.getElementById('cif').value.split(',');
  var message = document.getElementsByClassName('message')[0];
  
  for (var i = 0; i < cifs.length; ++i) {
    if (isValidCif(cifs[i].trim())) {
      message.innerHTML += '<br />CIF ' + cifs[i] + ' válido';
    } else {
      message.innerHTML += '<br />CIF ' + cifs[i] + ' no válido';
    }
  }
}

function validarPack(_numpack){
  //if ($("#selProducto_"+_numpack).val() == '' || parseInt($('#cantidad_'+_numpack).val()) == 0){
    if ($("#selProducto_"+_numpack).val() == ''){  
    return false;
  }else{
    return true;
  }
}

function validarPackCantidad(_numpack){
  if (parseInt($('#cantidad_'+_numpack).val()) == 0){
    return false;
  }else{
    return true;
  }
}



function contadorTextArea (campo,limite) {
  if (campo.value.length > limite) campo.value = campo.value.substring(0, limite);
  
}

function validaFechaDDMMAAAA(fecha){
  var dtCh= "-";
  var minYear=1900;
  var maxYear=2100;
  function isInteger(s){
    var i;
    for (i = 0; i < s.length; i++){
      var c = s.charAt(i);
      if (((c < "0") || (c > "9"))) return false;
    }
    return true;
  }
  function stripCharsInBag(s, bag){
    var i;
    var returnString = "";
    for (i = 0; i < s.length; i++){
      var c = s.charAt(i);
      if (bag.indexOf(c) == -1) returnString += c;
    }
    return returnString;
  }
  function daysInFebruary (year){
    return (((year % 4 == 0) && ( (!(year % 100 == 0)) || (year % 400 == 0))) ? 29 : 28 );
  }
  function DaysArray(n) {
    for (var i = 1; i <= n; i++) {
      this[i] = 31
      if (i==4 || i==6 || i==9 || i==11) {this[i] = 30}
      if (i==2) {this[i] = 29}
    }
    return this
  }
  function isDate(dtStr){
    var daysInMonth = DaysArray(12)
    var pos1=dtStr.indexOf(dtCh)
    var pos2=dtStr.indexOf(dtCh,pos1+1)
    var strDay=dtStr.substring(0,pos1)
    var strMonth=dtStr.substring(pos1+1,pos2)
    var strYear=dtStr.substring(pos2+1)
    strYr=strYear
    if (strDay.charAt(0)=="0" && strDay.length>1) strDay=strDay.substring(1)
    if (strMonth.charAt(0)=="0" && strMonth.length>1) strMonth=strMonth.substring(1)
    for (var i = 1; i <= 3; i++) {
      if (strYr.charAt(0)=="0" && strYr.length>1) strYr=strYr.substring(1)
    }
    month=parseInt(strMonth)
    day=parseInt(strDay)
    year=parseInt(strYr)
    if (pos1==-1 || pos2==-1){
      return false
    }
    if (strMonth.length<1 || month<1 || month>12){
      return false
    }
    if (strDay.length<1 || day<1 || day>31 || (month==2 && day>daysInFebruary(year)) || day > daysInMonth[month]){
      return false
    }
    if (strYear.length != 4 || year==0 || year<minYear || year>maxYear){
      return false
    }
    if (dtStr.indexOf(dtCh,pos2+1)!=-1 || isInteger(stripCharsInBag(dtStr, dtCh))==false){
      return false
    }
    return true
  }
  if(isDate(fecha)){
    return true;
  }else{
    return false;
  }
}

function esFechaValida(fecha){
  if (fecha != undefined && fecha != "" ){
    if (!/^\d{2}\-\d{2}\-\d{4}$/.test(fecha)){
//      alert("formato de fecha no vÃ¡lido (dd/mm/aaaa)");
      return false;
    }
    var dia  =  parseInt(fecha.substring(0,2),10);
    var mes  =  parseInt(fecha.substring(3,5),10);
    var anio =  parseInt(fecha.substring(6),10);
  switch(mes){
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      numDias=31;
      break;
    case 4: case 6: case 9: case 11:
      numDias=30;
      break;
    case 2:
      if (comprobarSiBisisesto(anio)){ numDias=29 }else{ numDias=28};
      break;
    default:
    //  alert("Fecha introducida errÃ³nea");
      return false;
  }
    if (dia>numDias || dia==0){
  //    alert("Fecha introducida errÃ³nea");
      return false;
    }
    return true;
  }
}
function comprobarSiBisisesto(anio){
if ( ( anio % 100 != 0) && ((anio % 4 == 0) || (anio % 400 == 0))) {
  return true;
  }
else {
  return false;
  }
}

function validarCP(_cp){
    
    var exr = /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/;
    return exr.test(_cp);

}
function validarProvinciaCP(_provincia, _cp){

  return false;
}

function validarIBAN__(iban) {
  var IBAN = iban.replace(/\s/g, '').toUpperCase();
  
  var paisesValidos = [
    "AD", "AE", "AL", "AT", "AZ", "BA", "BE", "BG", "BH", "BR", "BY", "CH", "CR",
    "CY", "CZ", "DE", "DK", "DO", "EE", "ES", "FI", "FO", "FR", "GB", "GE", "GI",
    "GL", "GR", "GT", "HR", "HU", "IE", "IL", "IS", "IT", "JO", "KW", "KZ", "LB",
    "LC", "LI", "LT", "LU", "LV", "MC", "MD", "ME", "MK", "MR", "MT", "MU", "NL",
    "NO", "PK", "PL", "PS", "PT", "QA", "RO", "RS", "SA", "SC", "SE", "SI", "SK",
    "SM", "ST", "SV", "TL", "TN", "TR", "UA", "VG", "XK"
  ];

  var codigoPais = IBAN.substring(0, 2);
  if (!paisesValidos.includes(codigoPais)) {
    return false; // El código de país no es válido
  }

  var digitosControl = IBAN.substring(2, 4);
  if (digitosControl !== "00") {
    return false; // Los dígitos de control deben ser "00"
  }
  
  // Verificar longitud y formato
  if (!IBAN.match(/^[A-Z]{2}[0-9]{2}[A-Z0-9]+$/)) {
    return false;
  }

    // Mover los primeros 4 caracteres al final
  IBAN = IBAN.substring(4) + IBAN.substring(0, 4);

  // Convertir letras a números
  var nuevoIBAN = '';
  for (var i = 0; i < IBAN.length; i++) {
    var char = IBAN.charAt(i);
    if (char.match(/[A-Z]/)) {
      nuevoIBAN += (char.charCodeAt(0) - 55).toString();
    } else {
      nuevoIBAN += char;
    }
  }

  // Realizar la división de acuerdo con la especificación IBAN
  var resto = 0;
  for (var j = 0; j < nuevoIBAN.length; j++) {
    resto = (resto * 10 + parseInt(nuevoIBAN.charAt(j), 10)) % 97;
  }

  // Comprobar si el residuo es igual a 1
  return resto === 1;
}


function validarIBAN(iban) {
  var iban = iban.replace(/\s/g, '').toUpperCase();
  var country = iban.substring(0, 2);
  var checkDigits = iban.substring(2, 4);
  var remaining = iban.substring(4);

  var formattedIban = remaining + country + checkDigits;
  var numericIban = '';

  var digitosControl = iban.substring(0, 4);
  
  if (iban.length < 24) {
    return false; 
  }

  if (iban.length == 24 && iban == 'ES8200000000000000000000'){
    return false;
  }


  for (var i = 0; i < formattedIban.length; i++) {
      var charCode = formattedIban.charCodeAt(i);
      if (charCode >= 65 && charCode <= 90) {
          numericIban += (charCode - 55).toString();
      } else {
          numericIban += formattedIban.charAt(i);
      }
  }

  var remainder = '';
  for (var j = 0; j < numericIban.length; j++) {
      var digit = parseInt(remainder + numericIban.charAt(j));
      remainder = digit % 97;
  }

  return remainder === 1;
}  


function validarIBAN__(iban) {
  var ibanSinEspacios = iban.replace(/\s/g, '').toUpperCase();
  //var ibanRegex = /^ES\d{20}$/; 
  //return ibanRegex.test(ibanSinEspacios);

  // = ibanSinEspacios.replace(/ /g, '').toUpperCase();
  iban = ibanSinEspacios;
  var countries = {
      'AL': 28, 'AD': 24, 'AT': 20, 'AZ': 28, 'BH': 22,
      'BE': 16, 'BA': 20, 'BR': 29, 'BG': 22, 'CR': 21,
      'HR': 21, 'CY': 28, 'CZ': 24, 'DK': 18, 'DO': 28,
      'TL': 23, 'EE': 20, 'FO': 18, 'FI': 18, 'FR': 27,
      'GE': 22, 'DE': 22, 'GI': 23, 'GR': 27, 'GL': 18,
      'GT': 28, 'HU': 28, 'IS': 26, 'IE': 22, 'IL': 23,
      'IT': 27, 'JO': 30, 'KZ': 20, 'KW': 30, 'LV': 21,
      'LB': 28, 'LI': 21, 'LT': 20, 'LU': 20, 'MK': 19,
      'MT': 31, 'MR': 27, 'MU': 30, 'MC': 27, 'MD': 24,
      'ME': 22, 'NL': 18, 'NO': 15, 'PK': 24, 'PS': 29,
      'PL': 28, 'PT': 25, 'QA': 29, 'RO': 24, 'SM': 27,
      'SA': 24, 'RS': 22, 'SK': 24, 'SI': 19, 'ES': 24,
      'SE': 24, 'CH': 21, 'TN': 24, 'TR': 26, 'AE': 23,
      'GB': 22, 'VG': 24
  };

  var countryCode = iban.substring(0, 2);
  if (!countries[countryCode] || iban.length !== countries[countryCode]) {
      return false;
  }

  var movedChar = (iban.substring(4) + iban.substring(0, 4)).split('');
  var newString = '';
  for (var i = 0; i < movedChar.length; i++) {
      if (!isNaN(parseInt(movedChar[i]))) {
          newString += movedChar[i];
      } else {
          newString += movedChar[i].charCodeAt(0) - 55;
      }
  }

  return parseInt(newString) % 97 === 1;
}




// Custom messages for own browser validations ****** ****** ****** ****** ****** ******
(function init_custom_validations(){
  var x = document.getElementsByClassName("input-nombre");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su nombre.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').addClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
  var x = document.getElementsByClassName("input-apellido1");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su primer apellido.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').addClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
  var x = document.getElementsByClassName("input-apellido2");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su segundo apellido.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').addClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
  var x = document.getElementsByClassName("input-email");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su dirección de email.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        case this.validity.typeMismatch:
          this.setCustomValidity('Revise su dirección de email.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
  var x = document.getElementsByClassName("input-telefono");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su número de teléfono.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').addClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
  var x = document.getElementsByClassName("input-movil");
  var i;
  for (i = 0; i < x.length; i++) {
    x[i].addEventListener('invalid', function(e) {
      switch(true){
        case this.validity.valueMissing:
          this.setCustomValidity('Introduzca su número de teléfono móvil.'); 
          $(this).parents('.form-group').addClass('has-error');
          $(this).parents('.form-group').removeClass('has-success');
          break;
        default:
          this.setCustomValidity('');
          $(this).parents('.form-group').removeClass('has-error');
          $(this).parents('.form-group').addClass('has-success');
          e.preventDefault();
      } 
    }, false);  
  };
})();

(function($) {

  $.fn.simpleAjaxForm = function(options) {
        var defaults = {
            url: ($(this).prop("tagName")=='FORM')?$(this).attr('action'):$(this).data('url'),
            ajaxTarget:($(this).data('ajax-target'))?$(this).data('ajax-target'):'',
            data: ($(this).prop('tagName')=='FORM') ? $(this).serialize() : $(this).closest('form').serialize(),
            spinTarget: $(this).data('id-spin-target'),
            mensajeError: "Se ha producido un error al procesar su peticion",
            strType:($(this).prop("tagName")=='FORM')?$(this).attr('method'):(($(this).data('srttype'))?$(this).data('srttype'):'GET'),
            callback: ($(this).data('callback'))?$(this).data('callback'):function(obj,target, result){}
        }

        var settings = $.extend( {}, defaults, options );
        if($(this).data('strtype')){
            settings.strType=$(this).data('strtype');
        }
        var url=settings.url,
            spinTarget=settings.spinTarget,
            ajaxTarget=settings.ajaxTarget,
            data=$(this).data('ajax-no-data') ? {} : settings.data,
            strType=settings.strType,
            callback=settings.callback,
            obj=($(this).prop("tagName")=='FORM')?$(this):$(this).closest('form');

           
        dataType = (ajaxTarget == '')?'json':'html';

        //spinner_holder = $(this).parents('.inner-contenedor-formulario').find('.js-spinner-holder');

        //spinner_holder.fadeIn(function(){
            //perform ajax request
            $.ajax({
                url: url,
                data: data,
                dataType: dataType,
                type:strType
            })
            .done( function (result) {
                if (dataType == 'html'){
                    $(ajaxTarget).html(result);                    
                }
                if ($.isFunction(window[callback])){window[callback](obj,ajaxTarget,result, '')}; // obj = formulario, ajaxTarget = target de los datos (puede ser = ''), result = resultado devuelto por el ajax
                //spinner_holder.fadeOut(); // el spinner lo oculta el callback
                
            })
            .fail(function() {
                result = '{"resultado":"KO","error":{"code":"90102","msg_error":"Ha ocurrido un error, vuelva a intentarlo más tarde."}}';
                if ($.isFunction(window[callback])){window[callback](obj,ajaxTarget,result, '')}; // obj = formulario, ajaxTarget = target de los datos (puede ser = ''), result = resultado devuelto por el ajax
                //spinner_holder.fadeOut(); // el spinner lo oculta el callback
            });

        //});

        


        //loader_spinner(spinTarget,'hide'); // Lo debe cerrar el callback
    };

//ANTI-PASTE
    $('.anti-paste').bind("cut copy paste",function(e) {
        e.preventDefault();
    });

//HAY QUE PARAMETRIZARLA!!!!!!!!!
  $.fn.simpleAjaxFormFiles = function(options) {
        var defaults = {
            url: ($(this).prop("tagName")=='FORM')?$(this).attr('action'):$(this).data('url'),
            ajaxTarget:($(this).data('ajax-target'))?$(this).data('ajax-target'):'',
            data: ($(this).prop('tagName')=='FORM') ? $(this).serialize() : $(this).closest('form').serialize(),
            spinTarget: $(this).data('id-spin-target'),
            mensajeError: "Se ha producido un error al procesar su peticion",
            strType:($(this).prop("tagName")=='FORM')?$(this).attr('method'):(($(this).data('srttype'))?$(this).data('srttype'):'GET'),
            callback: ($(this).data('callback'))?$(this).data('callback'):function(obj,target, result){}
        }
        var settings = $.extend( {}, defaults, options );
        if($(this).data('strtype')){
            settings.strType=$(this).data('strtype');
        }
        var url=settings.url,
            spinTarget=settings.spinTarget,
            ajaxTarget=settings.ajaxTarget,
            data=$(this).data('ajax-no-data') ? {} : settings.data,
            strType=settings.strType,
            callback=settings.callback,
            obj=($(this).prop("tagName")=='FORM')?$(this):$(this).closest('form');


       // var form = $(this);
       

        _formulario = $(this).attr('id');

        /*var itNombre = $("#itNombre").val();
 
        var itApellidos = $("#itApellidos").val();

        var itEmail = $("#itEmail").val();

        var itDNI = $("#itDNI").val();

        var itCP = $("#itCP").val();

        var itEmail = $("#itEmail").val();

        var check_recibir = $('#check_recibir').val();*/

        var file1 = $('#ficfoto1');  

        var archivo = file1[0].files;  


         // Crea un formData y lo envías
       // var formData = new FormData('form'); --ESTE FUNCIONA

        var formData = new FormData($('#'+_formulario).get(0));
        
        
        //formData.append('txttitulo',titulo);

        //formData.append('idparticipantes',idparticipantes);

        //formData.append('txttipo',txttipo);

        formData.append('files[]',archivo);  

        if ($('#ficfoto2').length > 0){//si hay dos ficheros
          var file2 = $('#ficfoto2');  

          var archivo2 = file2[0].files;  
          formData.append('files[]',archivo2);    
        }  
           


        dataType = 'html';
        
        //spinner_holder = $(this).parents('.inner-contenedor-formulario').find('.js-spinner-holder');
        
        //spinner_holder.fadeIn(function(){
            //perform ajax request
           


            $.ajax({
                url: url,
                type: "post",
                data: formData,
                dataType: "html",
                cache: false,
                contentType: false,
                processData: false
            })
            .done( function (result) {
                if (dataType == 'html'){
                    $(ajaxTarget).html(result);                    
                }
                if ($.isFunction(window[callback])){window[callback](obj,ajaxTarget,result, '')}; // obj = formulario, ajaxTarget = target de los datos (puede ser = ''), result = resultado devuelto por el ajax
                ////spinner_holder.fadeOut(); // el spinner lo oculta el callback
                
            })
            .fail(function() {
                result = '{"resultado":"KO","error":{"code":"90102","msg_error":"Ha ocurrido un error, vuelva a intentarlo más tarde."}}';
                if ($.isFunction(window[callback])){window[callback](obj,ajaxTarget,result, '')}; // obj = formulario, ajaxTarget = target de los datos (puede ser = ''), result = resultado devuelto por el ajax
               // //spinner_holder.fadeOut(); // el spinner lo oculta el callback
            });

       // });

        


        //loader_spinner(spinTarget,'hide'); // Lo debe cerrar el callback
    };


})(jQuery);

